<template>
    <div class="couriers" v-if="!userIsPoint">
        <v-row style="max-width: 2400px" class="mx-auto">
            <v-col cols="12">
                <v-card align="center" justify="center" class="mx-auto elevation-6">
                    <v-toolbar height="50" color="indigo darken-6 elevation-3" dark flat>
                        <v-toolbar-title class="overline">
                            {{ $t('couriers.list_of_couriers', { p1: couriersOrig.length, p2: couriersOnlineCount, p3: couriersFreeCount }) }}
                        </v-toolbar-title>

                        <v-spacer></v-spacer>

                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <span class="tooltip-text" v-bind="attrs" v-on="on">
                                    <v-btn @click="onClickBroadcast" class="px-2 mx-2" tile text rounded>
                                        <v-img src="../../assets/images/telegram-round-300.png" class="mr-2" width="24"></v-img>
                                        Рассылка
                                    </v-btn>
                                </span>
                            </template>
                            <span>Настроить Telegram-рассылку курьерам</span>
                        </v-tooltip>

                        <!-- <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <span class="tooltip-text" v-bind="attrs" v-on="on">
                                    <v-btn @click="onClickCalendar(true)" class="px-2 mx-2" tile text rounded>
                                        <v-img src="../../assets/images/excel-300.png" class="mr-1" width="30"></v-img>
                                        DarRush
                                    </v-btn>
                                </span>
                            </template>
                            <span>Выгрузить расчёт зарплаты курьеров c DarRush</span>
                        </v-tooltip> -->

                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <span class="tooltip-text" v-bind="attrs" v-on="on">
                                    <v-btn @click="onClickCalendar()" class="px-2 mx-2" tile text rounded>
                                        <v-img src="../../assets/images/excel-300.png" class="mr-1" width="30"></v-img>
                                        Выплаты курьерам
                                    </v-btn>
                                </span>
                            </template>
                            <span>Выгрузить расчёт зарплаты курьеров</span>
                        </v-tooltip>

                        <v-btn icon @click="onClickUpload">
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </v-toolbar>

                    <v-card-title class="pt-0 pb-2 d-flex justify-center">
                        <v-select class="mx-2 flex-grow-0" style="width: 220px" no-data-text="Нет данных" @change="updateData" :items="filter_statuses" name="order_mode" v-model="filter_status" label="По статусу" hide-details>
                            <template slot="selection" slot-scope="data">
                                <v-icon v-if="data.item.icon" small color="#444" class="mr-2">{{ data.item.icon }}</v-icon
                                >{{ data.item.text }}
                            </template>
                            <template slot="item" slot-scope="data">
                                <v-icon v-if="data.item.icon" small color="#444" class="mr-2">{{ data.item.icon }}</v-icon
                                >{{ data.item.text }}
                            </template>
                        </v-select>

                        <v-select class="mx-2 flex-grow-0" style="width: 220px" no-data-text="Нет данных" @change="updateData" :items="filter_enables" name="order_mode" v-model="filter_enable" label="По подключению" hide-details></v-select>

                        <v-select class="mx-2 flex-grow-0" style="width: 220px" no-data-text="Нет данных" @change="updateData" :items="filter_points" name="order_mode" item-text="name" item-value="id" v-model="filter_point" label="По точкам" hide-details></v-select>

                        <v-btn class="mt-4 mx-2" small @click="onClickWorkShiftCalendar">
                            <v-icon>mdi-calendar-clock</v-icon>
                        </v-btn>

                        <v-spacer></v-spacer>

                        <v-text-field style="width: 340px; flex: initial" v-model="search" append-icon="mdi-magnify" :label="$t('common.search')" single-line hide-details></v-text-field>
                    </v-card-title>
                    
                    <v-data-table
                        :mobile-breakpoint="0"
                        dense
                        no-data-text="Нет данных"
                        no-results-text="Нет данных, попробуйте изменить запрос"
                        :footer-props="{
                            pageText: '{0} - {1} из {2}',
                            itemsPerPageText: 'Показывать на странице',
                            itemsPerPageAllText: 'все',
                            showFirstLastPage: true,
                            itemsPerPageOptions: [25, 50, 100],
                        }"
                        :custom-sort="customSort"
                        :sort-by="sortBy"
                        :sort-desc="sortDesc"
                        :headers="headers"
                        :search="search"
                        :items="couriers"
                        item-key="index"
                        class="elevation-0"
                        :loading="loading"
                        :loading-text="$t('common.loading')"
                        @click:row="onClickCourierEdit"
                        @current-items="onTableCurrentItems"
                        @input="onTableInput"
                    >
                        <template v-slot:[`item.full_name`]="{ item }">
                            <v-avatar size="24" class="mr-2">
                                <img alt="courier" :src="`${config.api.delivery + 'user_avatar?tid=' + item.tid}`" />
                            </v-avatar>
                            <b>{{ item.full_name.length > 24 ? item.full_name.substr(0, 24) + '...' : item.full_name }}</b>
                        </template>

                        <template v-slot:[`item.status`]="{ item }">
                            <div v-if="item.enable" class="d-flex align-center">
                                <v-chip :color="courierStatusColorHtml(item.status)" text-color="white" class="ml-0">
                                    <v-icon size="small" class="mr-1">{{ courierStatusIcon(item.status) }}</v-icon>
                                    {{ courierStatusStr(item.status) }}
                                </v-chip>
                                <v-btn v-if="item.status != config.user.status.free || item.enable" outlined text tile x-small @click="onClickStatusEdit(item)"><v-icon small>mdi-pencil-outline</v-icon></v-btn>
                            </div>
                            <span v-else :style="{ color: courierConnectColor(item.enable) }">{{ courierConnectStatus(item.enable) }}</span>
                        </template>

                        <template v-slot:[`item.ordersToday`]="{ item }">
                            <span style="width: 35px; display: inline-block">{{ item.ordersToday }}</span>
                            <v-btn v-if="item.ordersToday" outlined text tile x-small @click="onClickTodayDetails(item)"><v-icon small>mdi-menu</v-icon></v-btn>
                        </template>

                        <template v-slot:[`item.ordersYesterday`]="{ item }">
                            <span style="width: 35px; display: inline-block">{{ item.ordersYesterday }}</span>
                            <v-btn v-if="item.ordersYesterday" outlined text tile x-small @click="onClickYesterdayDetails(item)"><v-icon small>mdi-menu</v-icon></v-btn>
                        </template>

                        <template v-slot:[`item.ordersMonth`]="{ item }">
                            <span style="width: 35px; display: inline-block">{{ item.ordersMonth }}</span>
                            <v-btn v-if="item.ordersMonth" outlined text tile x-small @click="onClickMonthDetails(item)"><v-icon small>mdi-menu</v-icon></v-btn>
                        </template>

                        <template v-slot:[`item.point_id`]="{ item }">
                            <div class="text-no-wrap">
                                <span class="mr-1" style="inline-block">{{ getPointName(item.point_id ? item.point_id : 0) }}</span>
                            </div>
                        </template>

                        <template v-slot:[`item.tariff`]="{ item }">
                            <div class="text-no-wrap">
                                <v-icon v-if="!item.settings || item.settings.tariff == 0" small>mdi-vector-rectangle</v-icon>
                                <span class="ml-1 mr-1">{{ getTariff(item) }}</span>
                            </div>
                        </template>

                        <template v-slot:[`item.date_register`]="{ item }">
                            <work-shift :data="workShiftDiff(item)" />
                        </template>

                        <template v-slot:[`item.date_enter`]="{ item }">
                            <span v-html="workShiftStart(item)"></span>
                        </template>

                        <template v-slot:[`item.date_exit`]="{ item }">
                            <span v-html="workShiftEnd(item)"></span>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>

        <broadcasts
            v-if="courierBroadcast"
            :users="couriersList"
            tag="couriers"
            @close="onBroadcastClose"
        />

        <div class="text-center">
            <v-dialog v-model="dialogStatus" width="unset">
                <v-card>
                    <v-card-title class="headline grey lighten-2">
                        {{ dialogHeader }}
                    </v-card-title>

                    <v-card-text>
                        <v-row>
                            <v-col cols="12" v-if="currentItem.status != config.user.status.free">
                                <div>
                                    <p>Заказы, которые доставляет курьер сделать как:</p>
                                    <v-radio-group v-model="orders_status" mandatory hide-details>
                                        <v-radio label="Новые" :color="orderStatusColorHtml(config.order.status.new)" :value="config.order.status.new"></v-radio>
                                        <v-radio label="Доставлены" :color="orderStatusColorHtml(config.order.status.complete)" :value="config.order.status.complete"></v-radio>
                                    </v-radio-group>
                                </div>
                            </v-col>
                            <v-col cols="12" class="d-flex px-4 pb-5 justify-md-space-around">
                                <v-btn class="ma-2" color="error" dark @click="onClickCourierDelete()">Отключить</v-btn>
                                <v-btn v-if="currentItem.status != config.user.status.free" :color="courierStatusColorHtml(config.user.status.free)" dark @click="onClickCourierFree()">Свободен</v-btn>
                            </v-col>
                        </v-row>

                        <v-divider></v-divider>
                        <span v-html="dialogContent"></span>
                    </v-card-text>
                </v-card>
            </v-dialog>

            <v-dialog v-model="dialogOrders" width="720" scrollable>
                <v-card :loading="loadingOrder">
                    <template slot="progress">
                        <v-progress-linear indeterminate absolute top color="deep-purple accent-4"></v-progress-linear>
                    </template>

                    <v-card-title class="headline grey lighten-2">
                        {{ dialogHeader }}
                    </v-card-title>

                    <v-card-text class="py-4">
                        <div class="text-center">
                            <v-pagination v-model="page" :length="Math.ceil(orders.length / perPage)"></v-pagination>
                        </div>
                        <span v-if="loadingOrder && !visibleOrders.length">{{ $t('common.loading') }}</span>
                        <div v-for="(order, i) of visibleOrders" :key="order.id" class="d-flex">
                            {{ (page - 1) * perPage + i + 1 }}.
                            <v-menu open-delay="750" open-on-hover :close-on-content-click="false" bottom offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs" v-on="on" class="item-order d-inline-block mx-3" :style="orderStatusStyle(order)">{{ order.id }}</span>
                                </template>

                                <v-card class="mx-auto" min-width="120" max-width="400" outlined>
                                    <div class="order-card" v-html="orderContent(order)"></div>
                                </v-card>
                            </v-menu>
                            <span v-html="orderListHeader(order)"></span>
                            <br />
                        </div>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn class="mx-2" color="primary" dark @click="updateDetails">Обновить</v-btn>
                        <v-btn class="mx-2" color="primary" text @click="dialogOrders = false">Закрыть</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="dialogEdit" width="440" scrollable>
                <v-card :loading="loadingEdit">
                    <template slot="progress">
                        <v-progress-linear indeterminate absolute top color="deep-purple accent-4"></v-progress-linear>
                    </template>

                    <v-card-title class="headline grey lighten-2">
                        {{ dialogHeader }}
                    </v-card-title>

                    <v-card-text>
                        <v-row class="my-1 py-0">
                            <v-col cols="5">
                                <v-avatar rounded="0" size="150">
                                    <img alt="courier" :src="`${config.api.delivery + 'user_avatar?tid=' + selectedItem.tid}`" />
                                </v-avatar>
                            </v-col>
                            <v-col cols="7" class="d-flex flex-column">
                                <v-text-field v-model="selectedItem.fname" label="Имя" hide-details></v-text-field>
                                <v-text-field v-model="selectedItem.lname" label="Фамилия" hide-details></v-text-field>
                                <v-text-field v-model="currentPhone" label="Номер телефона" hide-details></v-text-field>
                            </v-col>
                        </v-row>

                        <v-select class="flex-grow-0" no-data-text="Нет данных" :items="points" name="point" item-text="name" item-value="id" v-model="currentPoint" label="Привязать к точке" hide-details></v-select>

                        <v-text-field label="Ставка курьера, руб./заказ" id="tariff" name="tariff" type="number" min="0" v-model="currentTariff" hide-details></v-text-field>

                        <div class="py-2 d-flex">
                            <v-icon color="orange">mdi-information-outline</v-icon>
                            <span class="ml-1 text-tariff text-caption">Если ставка установлена в 0, то цена доставки определяется по геозонам</span>
                        </div>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                        <v-btn class="mx-2" color="success" dark @click="onClickSave">Сохранить</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn class="mr-5" color="error" dark text @click="promptDelete = true"> Удалить </v-btn>
                        <v-btn color="primary" text @click="dialogEdit = false">Закрыть</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="promptDelete" persistent max-width="290">
                <v-card>
                    <v-card-title class="text-h5"> Точно удалить? </v-card-title>
                    <v-card-text
                        >Восстановить курьера <b>{{ currentFullName }}</b> будет невозможно с помощью сайта, необходимо будет обращаться в службу поддержки, все равно удалить?</v-card-text
                    >
                    <v-card-actions>
                        <v-btn class="ml-5" color="green darken-1" text @click="promptDelete = false"> Отмена </v-btn>

                        <v-spacer></v-spacer>
                        <v-btn class="mr-5" color="error" @click="onClickDelete">Удалить</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog ref="dialog" v-model="calendar" width="320px">
                <v-card>
                    <v-toolbar height="50" color="indigo darken-6 elevation-3" dark flat>
                        <v-toolbar-title class="overline">
                            {{ datesText }}
                        </v-toolbar-title>
                    </v-toolbar>
                    <template>
                        <v-date-picker class="d-block" no-title range v-model="dates" locale="ru-ru" scrollable :selected-items-text="datesText" :first-day-of-week="1" @input="onClickChangeDate">
                            <v-spacer></v-spacer>
                            <v-btn v-if="workShift" class="mx-2 px-4" text color="primary" @click="onClickWorkShiftReset"> Сбросить </v-btn>
                            <v-btn v-else class="mx-2 px-4" text color="primary" @click="calendar = false"> Отмена </v-btn>
                            <v-btn class="mx-2 px-4" color="success" :style="dates.length !== 2 ? { color: '#999 !important' } : {}" dark @click="onClickDownloadData" :disabled="dates.length !== 2"> {{ workShift ? 'Установить' : 'Загрузить' }} </v-btn>
                        </v-date-picker>
                    </template>
                </v-card>
            </v-dialog>
        </div>

        <span class="pb-16 d-block" />
    </div>
</template>

<script>
    import config from '../../config'
    import moment from 'moment-timezone'

    moment.locale('ru')

    import DateTime from '../../mixins/DateTime.js'
    import Status from '../../mixins/Status.js'
    import Orders from '../../mixins/Orders.js'
    import broadcasts from '../broadcasts/Broadcasts.vue'
    import WorkShift from './WorkShift.vue'

    export default {
        mixins: [DateTime, Status, Orders],
        
        data() {
            return {
                promptDelete: false,
                loading: false,
                loadingOrder: false,
                loadingEdit: false,
                calendar: false,
                orders_status: 0,
                dates: [],
                config,
                search: '',
                sortBy: 'date_enter',
                sortDesc: true,
                headers: [
                    {
                        text: 'Имя Фамилия',
                        align: 'start',
                        value: 'full_name',
                        sortable: false,
                    },
                    {
                        text: 'Текущий статус ',
                        value: 'status',
                    },
                    {
                        text: 'Начало смены',
                        value: 'date_enter',
                    },
                    {
                        text: 'Конец смены',
                        value: 'date_exit',
                    },
                    {
                        text: 'Длительность смены',
                        value: 'date_register',
                    },
                    {
                        text: 'Заказы сегодня',
                        value: 'ordersToday',
                    },
                    {
                        text: 'Заказы вчера',
                        value: 'ordersYesterday',
                    },
                    {
                        text: 'Заказы за 30 дней',
                        value: 'ordersMonth',
                    },
                    {
                        text: 'Ставка',
                        value: 'tariff',
                    },
                    {
                        text: 'Привязка к точке',
                        value: 'point_id',
                    },
                ],
                couriers: [],
                couriersCurrent: [],
                couriersOrig: [],
                couriersOnlineCount: 0,
                couriersFreeCount: 0,
                dialogOrders: false,
                dialogEdit: false,
                dialogStatus: false,
                datesText: '',
                dialogHeader: '',
                dialogContent: '',
                currentItem: {},
                selectedItem: {},
                filter_status: -1,
                filter_statuses: [
                    {
                        text: '< Все >',
                        value: -1,
                    },
                    {
                        text: 'Свободен',
                        value: config.user.status.free,
                        icon: 'mdi-check-bold',
                    },
                    {
                        text: 'Обрабатывает',
                        value: config.user.status.processing,
                        icon: 'mdi-timer-sand',
                    },
                    {
                        text: 'Доставляет',
                        value: config.user.status.delivering,
                        icon: 'mdi-run-fast',
                    },
                ],
                filter_point: -1,
                filter_enable: -1,
                filter_enables: [
                    {
                        text: '< Все >',
                        value: -1,
                    },
                    {
                        text: 'Отключен',
                        value: 0,
                    },
                    {
                        text: 'Подключен',
                        value: 1,
                    },
                ],
                detailsCourier: 0,
                detailsDate1: '',
                detailsDate2: '',
                currentPoint: 0,
                currentTariff: 0,
                currentPhone: '',
                points: [],
                orders: [],
                filter_points: [],
                changeFlag: false,
                page: 1,
                perPage: config.paginateItemsCount,
                userIsPoint: false,
                darRush: false,
                courierBroadcast: false,
                workShift: false,
                workShiftDates: [],
                multiOrders: [],
            }
        },

        components: { WorkShift, broadcasts },

        mounted() {
            console.log('couriers mount')
            this.userIsPoint = this.$store.state.user.role != config.user.role.operator
            if (!this.userIsPoint) {
                this.uploadData()
            }
        },

        beforeDestroy() {},

        destroyed() {},

        watch: {},

        computed: {
            visibleOrders() {
                const allTypeOrders = [...this.orders, ...this.multiOrders]
                return allTypeOrders.slice((this.page - 1) * this.perPage, this.page * this.perPage)
            },
            currentFullName() {
                if (this.currentItem.full_name !== undefined) {
                    const courierName = this.currentItem.full_name
                    return courierName.length > 30 ? courierName.substr(0, 30) + '...' : courierName
                }
                return '-'
            },
            couriersList() {
                if (this.search.length) {
                    return this.couriersCurrent
                }
                return this.couriers
            }
        },

        methods: {

            customSort(items, index, isDesc) {
                if (index == 'date_register') {
                    // Длительность смены

                    items.sort((a, b) => {
                    if (!isDesc[0]) {
                            return b.enable - a.enable
                        } else {
                            return a.enable - b.enable
                        }
                    })
                    items.sort((a, b) => {
                    if (!isDesc[0]) {
                            return b.status - a.status
                        } else {
                            return a.status - b.status
                        }
                    })
                    items.sort((a, b) => {
                        let a_date_exit = moment()
                        let b_date_exit = moment()
                        if (!a.enable) a_date_exit = a.date_exit
                        if (!b.enable) b_date_exit = a.date_exit
                        if (a.date_enter && b.date_enter) {
                            const aDuration = moment.duration(moment(a_date_exit).diff(moment(a.date_enter)))
                            const bDuration = moment.duration(moment(b_date_exit).diff(moment(b.date_enter)))
                            if (!isDesc[0]) {
                                return bDuration._milliseconds - aDuration._milliseconds
                            } else {
                                return aDuration._milliseconds - bDuration._milliseconds
                            }
                        }
                        if (!a.date_enter && !b.date_enter) {
                            return -1
                        } else {
                            return 1
                        }
                    })
                    items.sort((a, b) => {
                    if (!isDesc[0]) {
                            return b.enable - a.enable
                        } else {
                            return a.enable - b.enable
                        }
                    })

                } else {

                    items.sort((a, b) => {
                        if (typeof a[index] !== 'undefined' && a[index] != null) {
                            if (typeof a[index] === 'string') {
                                if (!isDesc[0]) {
                                    return a[index].localeCompare(b[index], {sensitivity: 'base'})
                                } else {
                                    if (b[index] == null) {
                                        // все null опускает вниз всегда
                                        return a[index].localeCompare(b[index], {sensitivity: 'base'})
                                    }
                                    return b[index].localeCompare(a[index], {sensitivity: 'base'})
                                }
                            } else if (typeof a[index] === 'number') {
                                if (!isDesc[0]) {
                                    // console.log(a.enable)
                                    return a[index] - b[index]
                                } else {
                                    return b[index] - a[index]
                                }
                            }
                        }
                    })
                    if (index == 'status') {
                        items.sort((a, b) => {
                        if (!isDesc[0]) {
                                return b.enable - a.enable
                            } else {
                                return a.enable - b.enable
                            }
                        })
                    }
                }
                return items
            },

            workShiftDiff(courier) {
                if (courier.enable) {
                    if (courier.date_enter) {
                        const chip = this.timeDiff(moment(), courier.date_enter).replace('+', '')
                        return { text: '', chip, color: 'gray lighten-4', info: 'в смене' }
                    }
                } else if (courier.date_enter) {
                    if (courier.date_exit && moment(courier.date_exit).isSameOrAfter(courier.date_enter)) {
                        const chip = this.timeDiff(courier.date_exit, courier.date_enter, false)
                        // const chip = this.timeDiff(courier.date_exit, courier.date_enter).replace('+', '')
                        return { text: '', chip, color: 'green lighten-4', info: 'смена закрыта' }
                    }
                }
                return { text: '--' }
            },

            workShiftStart(courier) {
                let res = '--'
                if (courier.date_enter) {
                    res = this.timeToString(courier.date_enter)
                }
                return res
            },

            workShiftEnd(courier) {
                let res = '--'
                if (courier.date_enter && courier.date_exit) {
                    if (moment(courier.date_exit).isSameOrAfter(courier.date_enter)) {
                        res = this.timeToString(courier.date_exit)
                    }
                }
                return res
            },

            onTableInput(event) {
                console.log('tableInput', event)
            },

            onTableCurrentItems(items) {
                this.couriersCurrent = items
            },

            updatePaginate(event) {
                console.log('updatePaginate', event)
            },

            onClickChangeDate() {
                if (this.dates.length == 2) {
                    if (moment(this.dates[0]).isAfter(moment(this.dates[1]))) {
                        const date = this.dates[0]
                        this.dates[0] = this.dates[1]
                        this.dates[1] = date
                    }
                    this.datesText = moment(this.dates[0]).format('D MMM') + ' - ' + moment(this.dates[1]).format('D MMM')
                } else {
                    this.datesText = moment(this.dates[0]).format('D MMM')
                }
            },

            onClickWorkShiftReset() {
                this.calendar = false
                this.workShift = false
                this.dates = []
                this.workShiftDates = []
                this.updateData()
            },

            onClickDownloadData() {
                this.calendar = false
                console.log('dates', this.dates)
                if (this.workShift) {
                    this.workShiftDates = this.dates
                    this.updateData()
                } else {
                    let url
                    const d1 = this.getDate1StringFromDate(this.dates[0])
                    const d2 = this.getDate2StringFromDate(this.dates[1])
                    if (this.darRush) {
                        this.darRush = false
                        url = `${config.api.darRush}courier/salary?date2=${d2}&date1=${d1}`
                    } else {
                        url = `${config.api.delivery}couriers_salary?tz=${this.$store.state.settings.tz}&tz_string=${this.$store.state.settings.tz_string}&operator_tid=${this.$store.state.user.tid}&date1=${d1}&date2=${d2}&deliveryTime=true`
                    }
                    window.location.href = url
                }
            },

            onClickBroadcast() {
                this.courierBroadcast = true
            },

            onBroadcastClose() {
                this.courierBroadcast = false
            },

            onClickWorkShiftCalendar() {
                this.datesText = 'Фильтр по началу смены'
                this.workShift = true
                this.dates = this.workShiftDates
                this.calendar = true
            },

            onClickCalendar(rush) {
                this.datesText = 'Выберите даты "с" и "по"'
                this.calendar = true
                if (rush) this.darRush = true
                else this.darRush = false
                this.workShift = false
            },

            onClickCourierFree() {
                let courier = this.couriersOrig.find(courier => courier.tid == this.currentItem.tid)
                // console.log('free', courier)

                if (courier) {
                    courier.act_ord = 0
                    courier.status = config.user.status.free

                    this.$store
                        .dispatch('user_set', courier)
                        .then(res => {
                            if (res.data.success) {
                                console.log('res data', res.data)
                                this.couriersFreeCount++
                                this.updateData()
                                console.log('user saved ok')
                            } else {
                                console.log('order saved fail')
                            }
                        })
                        .catch(err => {
                            console.log(err)
                        })
                }

                this.$store
                    .dispatch('courier_orders_status', { courier_tid: courier.tid, status: this.orders_status })
                    .then(res => {
                        if (res.data.success) {
                            console.log('order saved ok')
                        } else {
                            console.log('order saved fail')
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    })

                this.dialogStatus = false
            },

            onClickCourierDelete() {
                const courier = this.couriersOrig.find(courier => courier.tid == this.currentItem.tid)

                if (courier) {
                    console.log('onClickCourierDelete', courier)
                    
                    courier.act_ord = 0
                    courier.status = config.user.status.free
                    courier.enable = 0
                    courier.date_exit = new Date()

                    this.$store
                        .dispatch('user_set', courier)
                        .then(res => {
                            if (res.data.success) {
                                console.log('user saved ok')
                                this.updateData()
                            } else {
                                console.log('order saved fail')
                            }
                            this.$emit('update')
                        })
                        .catch(err => {
                            console.log(err)
                        })
                        
                    this.$store
                        .dispatch('courier_orders_status', { courier_tid: courier.tid, status: this.orders_status })
                        .then(res => {
                            if (res.data.success) {
                                console.log('order saved ok')
                            } else {
                                console.log('order saved fail')
                            }
                        })
                        .catch(err => {
                            console.log(err)
                        })

                    this.notifyCourierStatus(courier)
                }
                this.dialogStatus = false
            },

            notifyCourierStatus(courier) {
                const data = {
                    courierName: courier.full_name,
                    courierTid: courier.tid,
                    operatorTid: courier.operator_tid,
                }
                this.$store
                    .dispatch('courier_status_notify', data)
                    .then(res => {
                        if (res.data.success) {
                            console.log('notify ok')
                        }
                    })
                    .catch(err => {
                        console.log('notify false', err.name, err.message)
                    })
            },

            onClickCourierEdit(item) {
                if (this.dialogStatus) return
                if (this.dialogOrders) return
                this.dialogHeader = `Курьер ${item.full_name}`
                this.currentItem = item

                if (this.currentItem.settings) {
                    if (!this.currentItem.settings.tariff) {
                        this.currentItem.settings.tariff = 0
                    }
                } else {
                    this.currentItem.settings = {}
                    this.currentItem.settings.tariff = 0
                }
                this.selectedItem = JSON.parse(JSON.stringify(this.currentItem))
                this.currentPoint = this.selectedItem.point_id
                this.currentTariff = this.selectedItem.settings.tariff
                this.currentPhone = this.selectedItem.settings.phone

                this.dialogEdit = true
            },

            onClickStatusEdit(item) {
                this.orders_status = config.order.status.complete
                this.dialogHeader = `Изменить статус для ${item.full_name}`
                this.dialogContent = `Текущий статус курьера: <span style="color: ${this.courierStatusColorHtml(item.status)};">${this.courierStatusStr(item.status)}</span>`
                this.currentItem = item
                this.dialogStatus = true
            },

            onClickTodayDetails(courier) {
                this.page = 1
                this.orders = []
                this.dialogHeader = `${courier.full_name} - заказы сегодня (${courier.ordersToday})`
                this.detailsCourier = courier.tid
                this.detailsDate1 = this.getDate1String()
                this.detailsDate2 = this.getDate2String()
                this.dialogContent = this.$t('common.loading')
                this.updateDetails()
                this.dialogOrders = true
            },

            onClickYesterdayDetails(courier) {
                this.page = 1
                this.orders = []
                this.dialogHeader = `${courier.full_name} - заказы вчера (${courier.ordersYesterday})`
                this.detailsCourier = courier.tid
                this.detailsDate1 = this.getDate1String(-1)
                this.detailsDate2 = this.getDate2String(-1)
                this.dialogContent = this.$t('common.loading')
                this.updateDetails()
                this.dialogOrders = true
            },

            onClickMonthDetails(courier) {
                this.page = 1
                this.orders = []
                this.dialogHeader = `${courier.full_name} - заказы за 30 дней (${courier.ordersMonth})`
                this.detailsCourier = courier.tid
                this.detailsDate1 = this.getDate1String(-29)
                this.detailsDate2 = this.getDate2String()
                this.dialogContent = this.$t('common.loading')
                this.updateDetails()
                this.dialogOrders = true
            },

            onClickSave() {
                // if (this.currentItem.full_name != this.selectedItem.full_name) {
                //     this.currentItem.full_name = this.selectedItem.full_name
                //     this.changeFlag = true
                // }
                if (this.currentItem.point_id != this.currentPoint) {
                    this.currentItem.point_id = this.currentPoint
                    this.changeFlag = true
                }
                if (this.currentItem.fname != this.selectedItem.fname) {
                    this.currentItem.fname = this.selectedItem.fname
                    this.changeFlag = true
                }
                if (this.currentItem.lname != this.selectedItem.lname) {
                    this.currentItem.lname = this.selectedItem.lname
                    this.changeFlag = true
                }
                if (this.currentItem.settings.tariff != this.currentTariff) {
                    this.currentItem.settings.tariff = this.currentTariff
                    this.changeFlag = true
                }
                if (this.currentItem.settings.phone != this.currentPhone) {
                    this.currentItem.settings.phone = this.currentPhone
                    this.changeFlag = true
                }

                this.dialogEdit = false

                if (this.changeFlag) {
                    this.$store
                        .dispatch('user_set', this.currentItem)
                        .then(res => {
                            if (res.data.success) {
                                console.log('user saved ok')
                            } else {
                                console.log('user saved fail')
                            }
                            this.changeFlag = false
                        })
                        .catch(err => {
                            console.log(err)
                        })
                }
            },

            onClickDelete() {
                this.dialogEdit = false
                this.promptDelete = false
                const courierId = this.currentItem.id
                this.$store.dispatch('user_delete', courierId).then(res => {
                    if (res.data.success) {
                        console.log('delete ok')
                        this.couriers = this.couriers.filter(couriers => couriers.id != courierId)
                        this.notifyDeleteCourier()
                    } else {
                        console.log('delete false')
                    }
                })
                // console.log('test currentItem = ', this.currentItem)
            },

            notifyDeleteCourier() {
                const data = {
                    courierName: this.currentItem.full_name,
                    courierTid: this.currentItem.tid,
                    operatorTid: this.currentItem.operator_tid,
                }
                this.$store
                    .dispatch('courier_delete_notify', data)
                    .then(res => {
                        if (res.data.success) {
                            console.log('notify ok')
                        }
                    })
                    .catch(err => {
                        console.log('notify false', err.name, err.message)
                    })
            },

            updateDetails() {
                this.loadingOrder = true

                this.$store
                    .dispatch('all_orders_date', {
                        courier: this.detailsCourier,
                        date1: this.detailsDate1,
                        date2: this.detailsDate2,
                        deliveryTime: true,
                    })
                    .then(res => {
                        this.dialogContent = ''
                        if (res.data.success) {
                            this.orders = res.data.orders.filter(o => o.status != config.order.status.forming)
                            this.multiOrders = res.data.multiOrders
                        } else {
                            this.dialogContent = this.$t('common.loading_error', { p1: 1 })
                        }
                        this.loadingOrder = false
                    })
                    .catch(err => {
                        this.loadingOrder = false
                        console.log('point_orders_get error', err)
                        this.dialogContent = this.$t('common.loading_error', { p1: 2 })
                    })
            },

            onClickUpload() {
                this.uploadData()
            },

            getTariff(item) {
                return item.settings && item.settings.tariff != 0 ? item.settings.tariff : 'По геозонам'
            },

            getPointName(point_id) {
                let pointName = 'Неизвестно'
                const point = this.points.find(p => p.id == point_id)
                if (point) {
                    pointName = point.name
                }
                return pointName
            },

            uploadData() {
                this.loading = true
                this.$store
                    .dispatch('couriers_get')
                    .then(res => {
                        if (res.data.success) {
                            this.couriersOnlineCount = 0
                            this.couriersOrig = res.data.couriers.map((courier, i) => {
                                if (courier.enable) this.couriersOnlineCount++
                                courier.address = 'не определен'
                                if (!courier.date_enter) {
                                    if (courier.date_exit) {
                                        courier.date_enter = courier.date_exit
                                    } else {
                                        courier.date_enter = '2021-12-09T00:00:00.123Z'
                                    }
                                }
                                return courier
                            })
                            this.couriers = JSON.parse(JSON.stringify(this.couriersOrig))

                            this.couriersFreeCount = this.couriers.filter(courier => courier.enable && courier.status == config.user.status.free).length
                            // console.log('couriers', this.couriers)
                            this.updateData()
                        }

                        this.$store
                            .dispatch('points_get')
                            .then(res => {
                                let points = []
                                // console.log('res.data', res.data)
                                if (res.data.success) {
                                    points = res.data.points
                                    // console.log('points', points)
                                }
                                this.points = points.map(point => ({ id: point.id, name: point.name }))
                                this.points.splice(0, 0, { id: 0, name: 'Для всех' })
                                this.filter_points = JSON.parse(JSON.stringify(this.points))
                                this.filter_points.splice(0, 0, { id: -1, name: '< Все >' })
                                this.loading = false
                            })
                            .catch(err => {
                                this.loading = false
                                console.log(err)
                            })
                    })
                    .catch(err => {
                        this.loading = false
                        console.log(err)
                    })
            },

            updateData() {
                this.couriers = JSON.parse(JSON.stringify(this.couriersOrig))
                if (this.filter_status != -1) {
                    this.couriers = this.couriers.filter(courier => courier.status == this.filter_status)
                }
                if (this.filter_enable != -1) {
                    this.couriers = this.couriers.filter(courier => courier.enable == this.filter_enable)
                }
                if (this.workShift) {
                    const d1 = this.getDate1StringFromDate(this.workShiftDates[0])
                    const d2 = this.getDate2StringFromDate(this.workShiftDates[1])
                    this.couriers = this.couriers.filter(c => moment(c.date_enter).isSameOrAfter(d1) && moment(c.date_enter).isSameOrBefore(d2))
                }
                for (let courier of this.couriers) {
                    courier.point_id = courier.point_id ? courier.point_id : 0
                    if (typeof courier.settings === 'object') {
                        if (courier.settings == null) {
                            courier.settings = {}
                            courier.settings.tariff = 0
                        } else if (!courier.settings.tariff) {
                            courier.settings.tariff = 0
                        }
                    } else {
                        try {
                            courier.settings = JSON.parse(courier.settings)
                            courier.settings.tariff = courier.settings.tariff ? courier.settings.tariff : 0
                        } catch (err) {
                            courier.settings = {}
                            courier.settings.tariff = 0
                        }
                    }
                }
                if (this.filter_point != -1) {
                    this.couriers = this.couriers.filter(courier => courier.point_id == this.filter_point)
                }
            },

            orderListHeader(order) {
                return ` <b>${order.name}</b> - ${moment(order.date).format('D MMM HH:mm')} - <span style="color: ${this.orderStatusColorHtml(order.status)};">${this.orderStatusStr(order.status)}${order.date_complete ? ` (${moment(order.date_complete).format('D MMM HH:mm')})` : ''}</span><br>`
            },

        },
    }
</script>

<style lang="stylus">

    .text-tariff
        color: #ff9800

    .couriers
        height 100%
        padding-top 10px !important

        .v-card
            cursor default

        .v-toolbar
            border-bottom-left-radius 0 !important
            border-bottom-right-radius 0 !important

        .v-data-table
            padding 0 16px
            .v-data-table__wrapper > table > thead > tr
                th
                    padding 0 8px
                    white-space nowrap
            .v-data-table__wrapper > table > tbody > tr
                cursor pointer
                // background none !important
                td
                    white-space nowrap
                    padding 0 8px
                    font-size 0.75rem
                    p
                        margin-top 6px
                        margin-bottom 6px

        .expanded-content
            box-shadow inset 0px 4px 8px -5px rgba(50, 50, 50, 0.75), inset 0px -4px 8px -5px rgba(50, 50, 50, 0.75)
            background none !important
            &:hover
                // cursor default !important

        .th-first-item
            width 30%

        .v-chip
            &.v-size--default
                font-size 0.75rem
                height 20px
                border-radius 16px
                margin 4px
                cursor pointer
                padding 0 6px

        b
            font-weight 500

    .couriers-dialog
        .v-list
            overflow-y auto
            height 132px
            .list-group
                height 100%
                .v-list-item
                    cursor pointer
                    &:hover
                        background-color beige
                .sortable-ghost
                    background-color beige
</style>
