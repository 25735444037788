<template>
    <div class="d-flex align-center">
        <span>{{ data.text }}</span>

        <v-tooltip top v-if="data.chip">
            <template v-slot:activator="{ on, attrs }">
                <v-chip :color="data.color" v-bind="attrs" v-on="on">{{ data.chip }}</v-chip>
            </template>
            <span>{{ data.info }}</span>
        </v-tooltip>
    </div>
</template>

<script>
    export default {
        props: ['data'],
    }
</script>
